import { useStaticQuery, graphql } from "gatsby"

import { flattenNode } from "services/arrays"

export const useAssessmentOptions = () => {
  let formFields = useStaticQuery(graphql`
    query AirtableAllAssessments {
      allAirtableallAssessments {
        nodes {
          data {
            Description
            ICD_Code
            Label
            Organ_System
          }
        }
      }
    }
  `)

  let assessmentOptions = flattenNode(formFields?.allAirtableallAssessments)

  assessmentOptions = assessmentOptions
    .map((option) => {
      return option.label
    })
    .sort((a, b) => {
      return a.localeCompare(b)
    })

  return assessmentOptions
}
