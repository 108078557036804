import moment from "moment"

import { convertBooleanToString } from "services/general"

const getConsultDateAndTime = (consultation) => {
  let consultDate = null

  switch (true) {
    case !!consultation?.schedule?.start?.dateTime:
      consultDate = consultation?.schedule?.start?.dateTime
      break
    case !!consultation?.startDateTime:
      consultDate = consultation?.startDateTime
      break
    case !!consultation?.consultationDateTime:
      consultDate = consultation?.consultationDateTime
      break
    case !!consultation?.created:
      consultDate = consultation?.created
      break
    default:
      consultDate = "N/A"
  }
  return {
    date: moment(consultDate).format("YYYY-MM-DD"),
    time: moment(consultDate).format("hh:mm A"),
  }
  // if (consultation?.schedule?.start?.dateTime) {
  //   date = moment
  //     .unix(consultation?.schedule?.start?.dateTime, "dateTime")
  //     .format("YYYY-MM-DD")
  //   time = moment
  //     .unix(consultation?.schedule?.start?.dateTime, "dateTime")
  //     .format("hh:mm A")
  // } else if (consultation?.consultationDateTime) {
  //   date = moment
  //     .unix(consultation?.consultationDateTime?.seconds, "dateTime")
  //     .format("YYYY-MM-DD")
  //   time = moment
  //     .unix(consultation?.consultationDateTime?.seconds, "dateTime")
  //     .format("hh:mm A")
  // } else if (consultation?.created) {
  //   date = moment
  //     .unix(consultation?.created?.seconds, "dateTime")
  //     .format("YYYY-MM-DD")
  //   time = moment
  //     .unix(consultation?.created?.seconds, "dateTime")
  //     .format("hh:mm A")
  // } else {
  //   date = "N/A"
  //   time = "N/A"
  // }
  // return { date, time }
}

const getMHPName = (email, consultation, type) => {
  let MHPName = ""
  MHPName = consultation?.schedule?.attendees?.find(
    (attendee) => attendee?.email === email
  )?.displayName
  return MHPName || email
}

const getGoogleMeetLink = (code) => {
  if (!!code) {
    return "https://meet.google.com/" + code
  } else {
    return null
  }
}

export const getConsultationStaticData = (
  staticData,
  latestConsultation,
  latestRPsyConsultation,
  latestMDConsultation
) => {
  let { schedule } = staticData
  let consultation = convertBooleanToString({ data: staticData })

  latestConsultation = convertBooleanToString({ data: latestConsultation })

  return {
    zendeskId: consultation?.zendeskId || "N/A",
    consultDate: getConsultDateAndTime(consultation).date,
    consultTime: getConsultDateAndTime(consultation).time,
    consultType: consultation?.consultType,
    consultInstance: consultation?.consultInstance || "Follow-up",
    consultationLink:
      schedule?.hangoutLink ||
      getGoogleMeetLink(schedule?.conferenceData?.conferenceId) ||
      "N/A",
    mhpName:
      consultation?.mhpName ||
      getMHPName(consultation.mhpEmail, consultation) ||
      consultation?.mhpEmail ||
      "N/A",
    name: `${consultation?.firstName} ${consultation?.lastName}` || "N/A",
    age: consultation?.birthday
      ? moment().diff(
          `${
            consultation?.birthday?.month?.value ||
            consultation?.birthday?.month
          } ${
            consultation?.birthday?.date?.value || consultation?.birthday?.date
          },${
            consultation?.birthday?.year?.value || consultation?.birthday?.year
          }`,
          "years"
        )
      : "N/A",
    sex: consultation?.sex || consultation?.gender || "N/A",
    email: consultation?.clientEmail || "N/A",
    personalEmail: consultation?.clientEmail || "N/A",
    mobileNumber: consultation?.mobileNumber || "N/A",
    company: consultation?.company || "N/A",
    emergencyContact: consultation?.emergencyContact?.name || "N/A",
    emergencyContactNumber:
      consultation?.emergencyContact?.mobileNumber || "N/A",
    emergencyRelationship:
      consultation?.emergencyContact?.relationship || "N/A",

    latestRPsyConsultation: !!latestRPsyConsultation?.startDateTime
      ? moment(latestRPsyConsultation?.startDateTime).format("YYYY-MM-DD")
      : "N/A",
    latestRPsy:
      latestRPsyConsultation?.mhpName ||
      getMHPName(
        latestRPsyConsultation.mhpEmail,
        latestRPsyConsultation,
        "RPSY"
      ) ||
      "N/A",
    latestDiagnosis: latestRPsyConsultation?.diagnosis || "N/A",
    latestRA: latestRPsyConsultation?.riskAssessment || "N/A",
    latestMDConsult: !!latestMDConsultation?.startDateTime
      ? moment(latestMDConsultation?.startDateTime).format("YYYY-MM-DD")
      : "N/A",
    latestMD:
      latestMDConsultation?.mhpName ||
      getMHPName(latestMDConsultation.mhpEmail, latestMDConsultation, "MD") ||
      "N/A",
    latestMDDiagnosis: latestMDConsultation?.diagnosis || "N/A",
    latestRiskAssessment: latestMDConsultation?.riskAssessment || "N/A",

    specificProblemIssue: latestConsultation?.specificProblemIssue || "N/A",
    causeOfDistress: latestConsultation?.causeOfDistress || "N/A",
    hasConsultedWithPsychInProgram:
      latestConsultation?.hasConsultedWithPsychInProgram || "N/A",
    hasBeenHospitalized: latestConsultation?.hasBeenHospitalized || "N/A",
    isTakingPsychMeds: latestConsultation?.isTakingPsychMeds || "N/A",
    isTakingNonPsychMeds: latestConsultation?.isTakingNonPsychMeds || "N/A",
    hasDrugAllergies: latestConsultation?.hasDrugAllergies || "N/A",
    hasBeenDiagnosed: latestConsultation?.hasBeenDiagnosed || "N/A",
    kesslerNervous: latestConsultation?.kesslerNervous || "N/A",
    kesslerHopeless: latestConsultation?.kesslerHopeless || "N/A",
    kesslerRestless: latestConsultation?.kesslerRestless || "N/A",
    kesslerDepressed: latestConsultation?.kesslerDepressed || "N/A",
    kesslerEffort: latestConsultation?.kesslerEffort || "N/A",
    kesslerWorthless: latestConsultation?.kesslerWorthless || "N/A",
    hasWishedToBeDead: latestConsultation?.hasWishedToBeDead || "N/A",
    hadSuicidalThoughts: latestConsultation?.hadSuicidalThoughts || "N/A",
    hasThoughtSuicidalMethod:
      latestConsultation?.hasThoughtSuicidalMethod || "N/A",
    hadSuicidalIntent: latestConsultation?.hadSuicidalIntent || "N/A",
    hadSuicidalIntentWithPlan:
      latestConsultation?.hadSuicidalIntentWithPlan || "N/A",
    hasActedOnSuicidalThoughts:
      latestConsultation?.hasActedOnSuicidalThoughts || "N/A",
    submissionDate:
      moment(latestConsultation?.created).format("YYYY-MM-DD hh:mm A") || "N/A",
  }
}
