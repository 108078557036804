import { getUpdatedFollowUpDate } from "../../MentalHealthForm/services/getUpdatedFollowUpDate.js"

export const updateFollowUpDate = (
  setFieldValue,
  option,
  event,
  formValues
) => {
  setFieldValue(
    "followUpDate",
    getUpdatedFollowUpDate(
      formValues?.startDateTime || formValues?.schedule?.end,
      option
    )
  )
}
