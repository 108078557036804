import moment from "moment"
export const getLatestConsultationByType = ({
  consultations,
  patientDetails,
  consultType,
}) => {
  let userConsultations = []
  userConsultations = consultations
    .filter((consultation) => {
      return consultation?.userId === patientDetails?.id
    })
    .filter((consultation) => {
      let isConsultType =
        consultation?.consultType?.toLowerCase() ===
          consultType?.toLowerCase() ||
        consultation?.mhpLicense?.toLowerCase() === consultType?.toLowerCase()

      let isCompleted =
        consultation?.consultStatus?.toLowerCase() === "completed" ||
        consultation?.consultStatus?.toLowerCase() === "done"
      return isConsultType && isCompleted
    })
    .sort(
      (a, b) =>
        moment(b.startDateTime).format("YYYY-MM-DD") -
        moment(a.startDateTime).format("YYYY-MM-DD")
      // b.consultationDateTime?.seconds * 1000 -
      // a.consultationDateTime?.seconds * 1000
    )

  return userConsultations.length !== 0 ? userConsultations[0] : {}
}

//get the most recent consult with an intake form
export const getLatestConsultation = ({ consultations, selectedPatient }) => {
  let userConsultations = consultations
    .filter((consultation) => {
      let isFirstTime = !!consultation?.consultInstance
        ? consultation?.consultInstance === "First-time" ||
          consultation?.consultInstance === "First Time"
        : true

      return consultation?.userId === selectedPatient?.id && isFirstTime
    })
    .sort(
      (a, b) =>
        moment(b.startDateTime).format("YYYY-MM-DD") -
        moment(a.startDateTime).format("YYYY-MM-DD")
    )
  return userConsultations[0]
    ? userConsultations[userConsultations.length - 1]
    : {}
}
