export const validateLeaveRecommendation = (values, errors) => {
  if (values?.leaveRecommendation === "With leave") {
    if (
      !values?.leaveStartDate?.date?.value ||
      !values?.leaveEndDate?.date?.value ||
      !values?.fitToWork?.value ||
      !values?.fitToWorkClearance?.value
    ) {
      if (!values?.leaveStartDate?.date?.value)
        errors.leaveStartDate = "This field is required."

      if (!values?.leaveEndDate?.date?.value)
        errors.leaveEndDate = "This field is required."

      if (!values?.fitToWork?.value)
        errors.fitToWork = "This field is required."

      if (!values?.fitToWorkClearance?.value)
        errors.fitToWorkClearance = "This field is required."

      return true
    }

    return false
  }
  return false
}
