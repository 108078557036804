import React, { useEffect, useContext, Fragment } from "react"
import { Formik, Form } from "formik"

import Section from "elements/Section"
import ColumnedSummary from "../../Elements/columnedSummary.js"
import CompleteMHForm from "./CompleteMHForm.js"
import NoShowMHForm from "./NoShowMHForm.js"

import { updateInitialValues } from "./services/updateInitialValues.js"
import { useMentalHealthFormFields } from "./hooks/useMentalHealthFormFields.js"
import { getConsultationStaticData } from "./services/getConsultationStaticData.js"
import { generateInitialValues } from "services/context"
import { generateFormField } from "elements/Form/services/form"
import {
  areAllFieldsOptional,
  isBrowser,
  isSectionStatic,
} from "services/general"

import {
  getLatestConsultation,
  getLatestConsultationByType,
} from "./services/getLatestConsultationData.js"

import { ConsultationContext } from "../ConsultationContext/ConsultationContext.js"

const MentalHealthForm = ({
  formFields,
  patientDetails,
  consultDetails,
  module,
  activeConsultations,
}) => {
  const { consultationDispatch } = useContext(ConsultationContext)

  let latestRPsyConsultation = {}
  let latestMDConsultation = {}
  let latestConsultation = {}

  latestRPsyConsultation = getLatestConsultationByType({
    consultations: activeConsultations,
    patientDetails,
    consultType: "Psychologist",
  })

  latestMDConsultation = getLatestConsultationByType({
    consultations: activeConsultations,
    patientDetails,
    consultType: "Psychiatrist",
  })

  latestConsultation = getLatestConsultation({
    consultations: activeConsultations,
    selectedPatient: patientDetails,
  })

  let initialValues = {
    ...generateInitialValues({ fields: formFields }),
    ...consultDetails,
    ...patientDetails,
  }

  initialValues = updateInitialValues(initialValues, formFields)

  const { sectionFormFields, validationSchema } = useMentalHealthFormFields({
    formFields: formFields,
    initialValues,
  })

  useEffect(() => {
    if (isBrowser()) consultationDispatch({ type: "GET_CONTEXT_FROM_SESSION" })
  }, [consultationDispatch])

  const renderConsultType = (section, errors, values, setFieldValue, index) => {
    if (section.section === "Consultation Status") {
      return (
        <Section
          title={section?.section}
          subtitle={section?.subtitle || ""}
          id={section?.sectionId || ""}
          addOns={
            {
              left: index + 1,
              sectionIsOptional: areAllFieldsOptional(section)
                ? "(optional)"
                : "",
            } || ""
          }
        >
          {section?.fields?.map((field) => {
            if (field.name === "consultStatus") {
              return (
                <Fragment>
                  {generateFormField({
                    formFields: section?.fields,
                    formField: field,
                    values,
                    setFieldValue,
                    errors,
                    showLabel: false,
                  })}
                </Fragment>
              )
            } else return null
          })}
        </Section>
      )
    }
  }

  const renderForm = (consultStatus, values, module) => {
    switch (consultStatus) {
      case "":
        break

      // return <ConsultStatusForm />
      case "Completed":
        return (
          <CompleteMHForm
            formFields={formFields}
            indexBuffer={sectionFormFields.length}
            view={consultStatus}
            module={module}
            consultDetails={consultDetails}
            values={values}
          />
        )

      // return <RestOfTheMHForm />
      case "No Show":
        // return <NoShowCOmponent />
        return (
          <NoShowMHForm
            values={values}
            view={consultStatus}
            module={module}
            consultDetails={consultDetails}
          />
        )
      default:
        return null
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize={true}
    >
      {({ values, setFieldValue, submitCount, errors }) => (
        <Form>
          {sectionFormFields
            .sort(
              (firstFormField, secondFormField) =>
                firstFormField.sectionId - secondFormField.sectionId
            )
            .map((section, index) => (
              <Fragment>
                <div className="is-flex is-flex-direction-row">
                  <div className="is-flex is-flex-direction-column is-flex-grow-1">
                    {!isSectionStatic(section) ? (
                      <Section
                        title={section?.section}
                        subtitle={section?.subtitle || ""}
                        id={section?.sectionId || ""}
                        addOns={
                          {
                            left: index + 1,
                            sectionIsOptional: areAllFieldsOptional(section)
                              ? "(optional)"
                              : "",
                          } || ""
                        }
                      >
                        <ColumnedSummary
                          setFieldValue={setFieldValue}
                          submitCount={submitCount}
                          errors={errors}
                          values={values}
                          section={section}
                          staticData={{
                            ...consultDetails,
                            ...patientDetails,
                          }}
                          getStaticData={getConsultationStaticData}
                          latestConsultation={latestConsultation}
                          latestRPsyConsultation={latestRPsyConsultation}
                          latestMDConsultation={latestMDConsultation}
                        />
                      </Section>
                    ) : (
                      renderConsultType(
                        section,
                        errors,
                        values,
                        setFieldValue,
                        index
                      )
                    )}
                  </div>
                </div>
              </Fragment>
            ))}
          {renderForm(values.consultStatus, values, module)}
        </Form>
      )}
    </Formik>
  )
}
export default MentalHealthForm
