import firebase from "firebase"
import moment from "moment"
import axios from "axios"

import { convertAllDateFieldsToDate } from "services/date"
import { createConsultationDocument } from "../../services/createConsultationDocument.js"
import { createDraftConsultationDocument } from "../../services/createDraftConsultationDocument.js"

import { GATSBY_MAKE_UPDATE_MH_CONSULT_WEBOOOK_URL } from "gatsby-env-variables"

import { uploadDocumentsToStorage } from "services/firebase/firebaseStorage"
import { convertToTitleCase } from "./../../../../services/general"

export const updateMHConsultation = async ({
  values,
  clientConsultation,
  submitType,
  errorCallback,
  formFields,
  consultationState,
}) => {
  let uploadedAttachmentUrls

  var converter = require("number-to-words")
  let userSnapshotId = clientConsultation.userId

  let patientConsultations = await JSON.parse(
    await sessionStorage.getItem("activeConsultations")
  )

  try {
    let consultationRef = firebase
      .firestore()
      .collection("users")
      .doc(userSnapshotId)
      .collection("consultations")
      .doc(clientConsultation.id)

    let consultation = await consultationRef.get()

    let CONSULTATION_RECORD

    switch (submitType) {
      case "saveAsDraft":
        CONSULTATION_RECORD = createDraftConsultationDocument({
          values,
          userSnapshotId,
        })

        if (consultationState?.documents) {
          uploadedAttachmentUrls = await uploadDocumentsToStorage({
            documents: consultationState.documents,
            path: `/${values?.patient?.email}/consultation`,
            docBaseName: `${moment().format("YYYYMMDD")}`,
            docType: "otherattachments",
          })
        }

        if (uploadedAttachmentUrls) {
          let attachments = []

          uploadedAttachmentUrls.map((attachment) =>
            attachments.push({
              firestoreURL: attachment.url,
              path: attachment.path,
              name: attachment.name,
              oldname: attachment.oldname,
              docType: attachment.type,
            })
          )
          CONSULTATION_RECORD["otherAttachments"] = attachments
        }

        break
      default:
        CONSULTATION_RECORD = createConsultationDocument({
          values,
          userSnapshotId,
        })
        //For now, this will be saved only if submitted not as draft since we cannot prefill submitted documents at the moment
        if (consultationState?.documents) {
          uploadedAttachmentUrls = await uploadDocumentsToStorage({
            documents: consultationState.documents,
            path: `/${values?.patient?.email}/consultation`,
            docBaseName: `${moment().format("YYYYMMDD")}`,
            docType: "otherattachments",
          })
        }

        if (uploadedAttachmentUrls) {
          let attachments = []

          uploadedAttachmentUrls.map((attachment) =>
            attachments.push({
              firestoreURL: attachment.url,
              path: attachment.path,
              name: attachment.name,
              oldname: attachment.oldname,
              docType: attachment.type,
            })
          )
          CONSULTATION_RECORD["otherAttachments"] = attachments
        }
    }

    // try {
    await consultationRef.update(CONSULTATION_RECORD)
    // } catch (error) {
    //   console.log({ error })
    //   if (errorCallback) errorCallback()
    // }

    let medicinesSnapshot = await consultationRef.collection("medicines").get()

    medicinesSnapshot.forEach((medicine) => {
      medicine.ref.delete()
    })

    let medicineDocuments = []

    if (values.medicine.length > 0) {
      await Promise.all(
        values.medicine.map(async (med) => {
          let medicineDocument = {
            drugName: med.drug || med.drugName || "N/A",
            qty: med?.quantity || med?.qty || med?.medicineQuantity || "N/A",
            brand: med?.brand[0] || "N/A",
            signa: med?.sig || med?.signa || "N/A",
            quantityInWords: convertToTitleCase(
              converter.toWords(
                med?.quantity || med?.qty || med?.medicineQuantity
              )
            ),
            form: med?.form || "N/A",
            strength: med?.strength || "N/A",
            s2: med?.s2 || false,
          }
          await firebase
            .firestore()
            .collection("users")
            .doc(userSnapshotId)
            .collection("consultations")
            .doc(consultation.id)
            .collection("medicines")
            .add(medicineDocument)
          medicineDocuments.push(medicineDocument)
        })
      )
    }

    let currentConsultations = patientConsultations?.filter(
      (consult) => consult.id !== consultation.id
    )

    sessionStorage.setItem(
      "activeConsultations",
      JSON.stringify([
        ...currentConsultations,
        {
          ...consultation.data(),
          ...CONSULTATION_RECORD,
          id: consultation.id,
          medicine: values.medicine || [],
        },
      ])
    )

    let formattedWebhookData = CONSULTATION_RECORD

    convertAllDateFieldsToDate({ data: formattedWebhookData, formFields })

    formattedWebhookData["specialistReferral"] = formattedWebhookData[
      "specialistReferral"
    ].length
      ? formattedWebhookData["specialistReferral"].map((field) => field.label)
      : []

    // try {
    await axios.post(GATSBY_MAKE_UPDATE_MH_CONSULT_WEBOOOK_URL, {
      ...consultation.data(),
      ...CONSULTATION_RECORD,
      ...formattedWebhookData,
      id: consultation.id,
      medicine: medicineDocuments,
    })
    // } catch (error) {
    //   console.log({ error })
    //   if (errorCallback) errorCallback()
    // }
  } catch (error) {
    console.log(error)
  }

  return null
}
