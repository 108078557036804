import firebase from "firebase"

import { getSignedInUser } from "../../../Auth/services/user"

export const editOnsiteConsultation = async ({ values, errorCallback }) => {
  let consultations = JSON.parse(sessionStorage.getItem("consultations"))

  const { organization } = getSignedInUser()

  try {
    var CONSULTATION_RECORD = {
      userId: values.userId,
      organizationId: organization.id,
      consultationDateTime: values.consultationDateTime,
      consultType: values.consultForm,
      chiefComplaint:
        values.chiefComplaint.value || values.chiefComplaint || "N/A",
      nurseAssessment:
        values?.nurseAssessment?.value || values?.nurseAssessment || "N/A",
      nurseNotes: values?.nurseNotes || "N/A",
      doctorAssessment:
        values?.doctorAssessment?.value || values?.doctorAssessment || "N/A",
      doctorNotes: values?.doctorNotes || "N/A",
      remarkableFindings: values?.remarkableFindings,
      doctorName: values?.doctorName?.value || values?.doctorName || "N/A",
      heartRate: values.heartRate,
      weight: values.weight,
      height: values.height,
      spo2: values.spo2,
      temperature: values.temperature,
      diastolicBloodPressure: values.diastolicBloodPressure,
      systolicBloodPressure: values.systolicBloodPressure,
      historyOfPresentIllness: values.historyOfPresentIllness,
      respiratoryRate: values.respiratoryRate,
      otherNotes: values.otherNotes,
      diagnosis: null,
      clientDiagnosis: null,
      recommendation:
        values.recommendation.value || values.recommendation || "N/A",
      otherPrescriptionNotes: values.otherNotes,
      endorsements: null,
      consultLoggedBy: values.consultLoggedBy || "N/A",
      waiveVitals: values.waiveVitals,
      issuedMedical: values.issuedMedical,
    }

    const consultationRef = firebase
      .firestore()
      .collection("users")
      .doc(values.userId)
      .collection("consultations")
      .doc(values.consultationId)

    await consultationRef.update(CONSULTATION_RECORD)

    let medicinesSnapshot = await consultationRef.collection("medicines").get()

    medicinesSnapshot.forEach((medicine) => {
      medicine.ref.delete()
    })

    if (values.medicine.length > 0) {
      await Promise.all(
        values.medicine.map((med) =>
          firebase
            .firestore()
            .collection("users")
            .doc(values.userId)
            .collection("consultations")
            .doc(values.consultationId)
            .collection("medicines")
            .add({
              drugName: med.drug || med.drugName || "N/A",
              qty: med.quantity || med.qty || med.medicineQuantity || "N/A",
              brand: med.brand || "N/A",
            })
        )
      )
    }

    consultations[
      consultations.findIndex(
        (consultation) =>
          consultation.enrollmentId === CONSULTATION_RECORD.enrollmentId
      )
    ] = {
      ...CONSULTATION_RECORD,
      patient: values.patient,
      consultationId: values.consultationId,
      medicine: values.medicine?.map((med) => {
        return {
          productTitle: med.productTitle,
          quantity: med.quantity || med.qty || "N/A",
          brand: med.brand || "N/A",
        }
      }),
    }

    sessionStorage.setItem("consultations", JSON.stringify(consultations))
  } catch (error) {
    if (errorCallback) errorCallback(error)
  }

  return null
}
