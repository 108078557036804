import React, { Fragment } from "react"

import { generateFormField } from "elements/Form/services/form"
import { getConsultType } from "services/general"

const VitalsSection = ({
  section,
  values,
  setFieldValue,
  submitCount,
  errors,
  consultType,
}) => {
  return (
    <Fragment>
      {section?.fields.map((field) => {
        if (
          field.name === "weight" &&
          field.consultType === getConsultType(consultType)
        ) {
          return (
            <span className="label has-text-weight-normal has-text-left">
              Vital Signs
            </span>
          )
        } else return null
      })}
      {section?.fields.map((field) => {
        if (
          field.name === "weight" &&
          field.consultType === getConsultType(consultType)
        ) {
          //separate vitals
          return (
            <div className="columns">
              <div className="column is-3">
                {section?.fields.map((field) => {
                  if (
                    (field.name === "weight" ||
                      field.name === "height" ||
                      field.name === "spo2") &&
                    field.consultType === getConsultType(consultType)
                  ) {
                    return (
                      <Fragment>
                        {generateFormField({
                          formFields: section?.fields,
                          formField: field,
                          values,
                          setFieldValue,
                          submitCount,
                          errors,
                        })}
                      </Fragment>
                    )
                  } else return null
                })}
              </div>
              <div className="column is-5 px-1">
                {section?.fields.map((field) => {
                  if (
                    field.name === "temperature" &&
                    field.consultType === getConsultType(consultType)
                  ) {
                    return (
                      <Fragment>
                        {generateFormField({
                          formFields: section?.fields,
                          formField: field,
                          values,
                          setFieldValue,
                          submitCount,
                          errors,
                        })}
                      </Fragment>
                    )
                  } else {
                    return null
                  }
                })}
                <div className="columns">
                  {section?.fields.map((field) => {
                    if (
                      field.name === "systolicBloodPressure" &&
                      field.consultType === getConsultType(consultType)
                    ) {
                      return (
                        <div className="column is-7 pr-0">
                          <Fragment>
                            {generateFormField({
                              formFields: section?.fields,
                              formField: field,
                              values,
                              setFieldValue,
                              submitCount,
                              errors,
                            })}
                          </Fragment>
                        </div>
                      )
                    } else if (
                      field.name === "diastolicBloodPressure" &&
                      field.consultType === getConsultType(consultType)
                    ) {
                      return (
                        <div className="column is-5 pl-0">
                          <Fragment>
                            {generateFormField({
                              formFields: section?.fields,
                              formField: field,
                              values,
                              setFieldValue,
                              submitCount,
                              errors,
                            })}
                          </Fragment>
                        </div>
                      )
                    } else {
                      return null
                    }
                  })}
                </div>
              </div>
              <div className="column is-4">
                {section?.fields.map((field) => {
                  if (
                    (field.name === "heartRate" ||
                      field.name === "respiratoryRate") &&
                    field.consultType === getConsultType(consultType)
                  ) {
                    return (
                      <Fragment>
                        {generateFormField({
                          formFields: section?.fields,
                          formField: field,
                          values,
                          setFieldValue,
                          submitCount,
                          errors,
                        })}
                      </Fragment>
                    )
                  } else {
                    return null
                  }
                })}
              </div>
            </div>
          )
        } else return null
      })}
    </Fragment>
  )
}

export default VitalsSection
