import React, { useContext, Fragment } from "react"

import classNames from "classnames"

import { AppContext } from "context/AppContext"

const WaiveVitalsModal = ({ formFields, setFieldValue }) => {
  const { dispatch } = useContext(AppContext)

  const handleCloseModal = () => {
    setFieldValue("waiveVitals", [])
    dispatch({ type: "HIDE_MODAL" })
  }

  const handleWaivePatient = () => {
    formFields.forEach((field) => {
      if (field.formFieldType.includes("vitals"))
        setFieldValue(field.name, "N/A")
    })
    dispatch({ type: "HIDE_MODAL" })
  }

  return (
    <div className="has-text-centered columns is-centered">
      <div className="column">
        <h4 className="mt-2 has-text-primary">
          Are you sure you want to waive this?
        </h4>
        <p>Measuring vital signs is important for patient care</p>
        <div className="buttons is-centered mt-2 mb-1">
          <Fragment>
            <button className={classNames("button")} onClick={handleCloseModal}>
              Cancel
            </button>
            <button
              className={classNames("button is-primary")}
              onClick={handleWaivePatient}
            >
              Yes, I understand
            </button>
          </Fragment>
        </div>
      </div>
    </div>
  )
}

export default WaiveVitalsModal
