export const createDraftConsultationDocument = ({ values, userSnapshotId }) => {
  // const dateNow = new Date()

  try {
    switch (values?.consultForm) {
      case "Teleconsult":
      case "Physical Health":
        return {
          isDraft: true,
          userId: userSnapshotId || "",
          startDateTime: values?.consultationDateTime,
          consultType: values?.consultForm || "",
          chiefComplaint:
            values?.chiefComplaint.value || values?.chiefComplaint || "",
          nurseAssessment:
            values?.nurseAssessment?.value || values?.nurseAssessment || "",
          doctorAssessment:
            values?.doctorAssessment?.value || values?.doctorAssessment || "",
          remarkableFindings: values?.remarkableFindings,
          doctor: values?.doctorName?.value || values?.doctorName || "",
          heartRate: values?.heartRate || "",
          weight: values?.weight || "",
          height: values?.height || "",
          spo2: values?.spo2 || "",
          temperature: values?.temperature || "",
          diastolicBloodPressure: values?.diastolicBloodPressure || "",
          systolicBloodPressure: values?.systolicBloodPressure || "",
          history: values?.historyOfPresentIllness || "",
          respiratoryRate: values?.respiratoryRate || "",
          otherNotes: values?.otherNotes || "",
          diagnosis: null,
          clientDiagnosis: null,
          recommendation:
            values?.recommendation?.value || values?.recommendation || "",
          otherPrescriptionNotes: values?.otherNotes || "",
          endorsements: null,
        }
      case "Mental Health":
        return {
          isDraft: true,
          userId: userSnapshotId || "",
          // startDateTime: dateNow.toISOString() || "",
          consultStatus: values?.consultStatus || "",
          consultReason: values?.consultReason || "",
          historyOfPresentIllness: values?.historyOfPresentIllness || "",
          sessionNotes: values?.sessionNotes || "",
          diagnosis: values?.diagnosis || "",
          diagnosisVisibleToClient: values?.diagnosisVisibleToClient || "",
          recommendations: values?.recommendations || "",
          leaveRecommendation: values?.leaveRecommendation || "",
          leaveEndDate: {
            month: values?.leaveEndDate?.month.value || "",
            date: values?.leaveEndDate?.date.value || "",
            year: values?.leaveEndDate?.year.value || "",
          },
          leaveStartDate: {
            month: values?.leaveStartDate?.month.value || "",
            date: values?.leaveStartDate?.date.value || "",
            year: values?.leaveStartDate?.year.value || "",
          },
          fitToWork: values?.fitToWork.value || "",
          fitToWorkClearance: values?.fitToWorkClearance.value || "",
          otherRecommendedAccomodations:
            values?.otherRecommendedAccomodations || "",
          labRequest: values?.labRequest || "",
          riskAssessment: values?.riskAssessment || "",
          followUpDate: values?.followUpDate || "",
          followUpDateMHP: {
            month: values?.followUpDateMHP?.month.value || "",
            date: values?.followUpDateMHP?.date.value || "",
            year: values?.followUpDateMHP?.year.value || "",
          },
          specificRecommendation:
            values?.specificRecommendation?.value ||
            values?.specificRecommendation ||
            [],
          specialistReferral: values?.specialistReferral || [],
          followUpDuration: values?.followUpDuration.value || "",
          endorsement: values?.endorsement || "",
        }
      case "Best Life":
        return {}
      default:
        return {}
    }
  } catch (err) {
    console.log(err)
  }
}
