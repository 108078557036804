import { createValidationSchema } from "services/validations"
import { parseFormField } from "services/airtable"
import { getSignedInUser } from "../../../Auth/services/user"
import { disableDraftFormFields } from "../../services/disableDraftFormFields"

export const useMentalHealthFormFields = ({ formFields, initialValues }) => {
  const { hiddenFieldsNames } = getSignedInUser().permission || {}

  let mentalHealthFormFields = formFields.filter((field) => {
    return (
      (!hiddenFieldsNames?.includes(field.name) &&
        field?.consultType === "MGX1SOLMH" &&
        field?.formFieldType[0] === "static") ||
      field?.name === "consultStatus" ||
      field?.name === "followUpDate"
    )
  })

  let finalFormFields = disableDraftFormFields(
    { mentalHealthFormFields },
    initialValues?.isDraft
  )

  return {
    sectionFormFields: parseFormField(finalFormFields),
    validationSchema: createValidationSchema({ fields: finalFormFields }),
  }
}
