import React, { useContext, useEffect } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheck } from "@fortawesome/free-solid-svg-icons"
import classNames from "classnames"

import styles from "./utils/elements.module.scss"
import { AppContext } from "context/AppContext"

const SuccessAddModal = ({ message, type }) => {
  const { state, dispatch } = useContext(AppContext)

  useEffect(() => {
    setTimeout(() => {
      dispatch({ type: "HIDE_MODAL" })
    }, 1500)
  }, [state.modal.isActive, dispatch])

  return (
    <div className="has-text-centered columns is-centered">
      <div className={classNames(styles[`modal__addedForm`])}>
        <div className={classNames("has-text-white", styles[`circleCheck`])}>
          <FontAwesomeIcon icon={faCheck} />
        </div>
        <h4 className="mt-2 has-text-primary">{message}</h4>
      </div>
    </div>
  )
}

export default SuccessAddModal
