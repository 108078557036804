import { emptyFollowUpFieldsInitialValues } from "../../../../services/general"

export const updateInitialValues = (
  initialValues,
  formFields,
  calendarConsultation
) => {
  initialValues.consultForm = "Mental Health"

  formFields.forEach((formField) => {
    if (
      formField.formFieldType[0] === "date" &&
      formField.consultType === "MGX1SOLMH"
    ) {
      for (const property in initialValues[formField.name]) {
        if (typeof initialValues[formField.name][property] !== "object") {
          initialValues[formField.name][property] = {
            value: initialValues[formField.name][property],
            label: initialValues[formField.name][property],
          }
        }
      }
    }
    if (
      formField.formFieldType[0] === "select" &&
      formField.consultType === "MGX1SOLMH"
    ) {
      if (typeof initialValues[formField.name] !== "object") {
        initialValues[formField.name] = {
          value: initialValues[formField.name],
          label: initialValues[formField.name],
        }
      }
    } else if (
      formField.formFieldType[0] === "multiselect" &&
      formField.consultType === "MGX1SOLMH"
    ) {
      if (
        typeof initialValues[formField.name] === "string" &&
        initialValues[formField.name].length > 0
      ) {
        initialValues[formField.name] = [
          {
            label: initialValues[formField.name],
            value: initialValues[formField.name],
          },
        ]
      } else if (
        typeof initialValues[formField.name] === "object" &&
        initialValues[formField.name].length > 0
      ) {
        initialValues[formField.name] = calendarConsultation?.[
          formField.name
        ].map((selection) => {
          return { value: selection.value, label: selection.label }
        })
      } else initialValues[formField.name] = []
    }
  })

  initialValues.followUpDate =
    calendarConsultation?.consultation?.riskAssessment !== "No Psychopathology"
      ? initialValues?.followUpDate
      : "No follow-up date"

  // sets initial values of hidden form fields to "N/A" to bypass field requirement
  formFields.forEach((field) => {
    if (
      field?.isFollowUpQuestion &&
      field?.referenceAnswer !==
        initialValues[field?.referenceQuestionName?.[0]]
    ) {
      initialValues[field?.name] = emptyFollowUpFieldsInitialValues(field)
    }
  })

  return initialValues
}
