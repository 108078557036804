import moment from "moment"

export const getUpdatedFollowUpDate = (date, riskAssessment) => {
  let dateBuffer = 0
  switch (riskAssessment.toLowerCase()) {
    case "no psychopathology":
      return "No follow-up date"
    case "severe":
      dateBuffer = 7
      break
    case "moderately severe":
      dateBuffer = 21
      break
    case "mild":
      dateBuffer = 56
      break
    case "moderate":
      dateBuffer = 42
      break
    default:
      dateBuffer = 0
  }

  let newDate = new Date(moment(date).format("YYYY-MM-DD"))
  newDate.setDate(newDate.getDate() + dateBuffer)

  return newDate.toLocaleDateString()
}
