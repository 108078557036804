import firebase from "firebase"

import { getSignedInUser } from "../../../Auth/services/user"
import { createConsultationDocument } from "../../services/createConsultationDocument.js"

export const addOnsiteConsultation = async ({ values, errorCallback }) => {
  let userSnapshotId

  const { organization } = getSignedInUser()
  try {
    const userSnapshot = await firebase
      .firestore()
      .collection("users")
      .where("email", "==", values.patient[0].email)
      .get()

    userSnapshot.forEach((user) => {
      userSnapshotId = user.id
    })

    var CONSULTATION_RECORD = createConsultationDocument({
      values,
      userSnapshotId,
      organization,
    })

    let consultation = await firebase
      .firestore()
      .collection("users")
      .doc(userSnapshotId)
      .collection("consultations")
      .doc()

    consultation.set({
      ...CONSULTATION_RECORD,
      consultationId: consultation.id,
    })

    if (values.medicine.length > 0) {
      await Promise.all(
        values.medicine.map((med) =>
          firebase
            .firestore()
            .collection("users")
            .doc(userSnapshotId)
            .collection("consultations")
            .doc(consultation.id)
            .collection("medicines")
            .add({
              drugName: med.drug || med.drugName,
              qty: med.quantity || med.qty || med.medicineQuantity || "N/A",
              brand: med.brand || "N/A",
            })
        )
      )
    }

    let consultations = JSON.parse(sessionStorage.getItem("consultations"))

    sessionStorage.setItem(
      "consultations",
      JSON.stringify([
        ...consultations,
        {
          ...CONSULTATION_RECORD,
          patient: values.patient[0],
          consultationId: consultation.id,
          medicine: values.medicine || [],
        },
      ])
    )
  } catch (error) {
    if (errorCallback) errorCallback(error)
  }

  return null
}
