import { createValidationSchema } from "services/validations"
import { parseFormField } from "services/airtable"
import { getSignedInUser } from "../../../Auth/services/user"

export const useOnsiteFormFields = ({ formFields }) => {
  const { hiddenFieldsNames, viewOnlyFieldsNames } =
    getSignedInUser().permission || {}

  let finalFormFields = formFields.filter((field) => {
    return (
      !hiddenFieldsNames?.includes(field.name) &&
      (field?.consultType === "MGX1SOLCL" || field?.consultType === null)
    )
  })

  finalFormFields.forEach((field) => {
    if (viewOnlyFieldsNames?.includes(field.name)) {
      field.isDisabled = true
      field.disabled = true
      field.isRequired = false
    }
  })

  return {
    sectionFormFields: parseFormField(finalFormFields),
    validationSchema: createValidationSchema({ fields: finalFormFields }),
  }
}
