import React from "react"
import WaiveVitalsModal from "../ConsultationFormPage/WaiveVitalsModal"

export const handleWaiveCheckbox = ({
  value,
  setFieldValue,
  dispatch,
  formFields,
}) => {
  if (value.length) {
    dispatch({
      type: "SHOW_MODAL",
      payload: {
        isCard: true,
        headerClass: `has-text-info has-background-info-light has-text-weight-bold is-size-5`,
        content: (
          <WaiveVitalsModal
            formFields={formFields}
            setFieldValue={setFieldValue}
          />
        ),
      },
    })
  } else {
    formFields.forEach((field) => {
      if (field.formFieldType.includes("vitals")) setFieldValue(field.name, "")
    })
  }
}
