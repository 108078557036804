export const disableDraftFormFields = ({ mentalHealthFormFields }, isDraft) => {
  let finalFormFields = mentalHealthFormFields
  finalFormFields.forEach((field) => {
    field.required = true
    field.isRequired = true

    if (isDraft === false) {
      field.required = true
      field.isRequired = true
      field.disabled = true
      field.isDisabled = true
    } else {
      field.disabled = false
      field.isDisabled = false
    }
  })

  return finalFormFields
}
