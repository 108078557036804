import { createValidationSchema } from "services/validations"
import { parseFormField } from "services/airtable"
import { getSignedInUser } from "../../../Auth/services/user"

import {
  GATSBY_MHP_ROLE_ID,
  GATSBY_PSYCHIATRIST_ROLE_ID,
} from "gatsby-env-variables"

const getUserRole = (userData) => {
  switch (userData.role) {
    case GATSBY_MHP_ROLE_ID:
      return "psychologist"
    case GATSBY_PSYCHIATRIST_ROLE_ID:
      return "psychiatrist"
    default:
      return
  }
}

const getConsultStatusString = (consultation) => {
  if (consultation?.isDraft === false) return "Submitted"
  else if (
    consultation?.consultStatus === "tentative" ||
    consultation?.consultStatus === "confirmed"
  )
    return "Upcoming"
  else if (consultation?.dateDocumentsSend) return "Completed"
  else return "Incomplete"
}

export const useCompleteMentalHealthFormFields = ({ formFields, values }) => {
  const { hiddenFieldsNames, viewOnlyFieldsNames } =
    getSignedInUser().permission || {}
  const { userData } = getSignedInUser()
  const userRole = getUserRole(userData)

  let consultStatus = getConsultStatusString(values)

  let finalFormFields = formFields.filter((field) => {
    return (
      !hiddenFieldsNames?.includes(field.name) &&
      field?.consultType === "MGX1SOLMH" &&
      field?.formFieldType[0] !== "static" &&
      field?.name !== "consultStatus"
    )
  })

  if (userData.role !== GATSBY_PSYCHIATRIST_ROLE_ID) {
    finalFormFields = finalFormFields.filter((field) => {
      return !(field?.name === "medicine" || field?.name === "labRequest")
    })
  }

  finalFormFields.forEach((field) => {
    if (consultStatus === "Submitted") {
      field.disabled = true
      field.isDisabled = true
    } else {
      field.disabled = false
      field.isDisabled = false
    }

    if (
      viewOnlyFieldsNames?.includes(field.name) ||
      field?.name === "patient" ||
      field?.name === "followUpDate"
    ) {
      field.isDisabled = true
      field.disabled = true
      field.isRequired = false
    } else if (
      field?.name === "endorsement" ||
      field?.name === "otherRecommendedAccomodations" ||
      field?.name === "labRequest" ||
      field?.name === "specialistReferral"
    ) {
      field.required = false
      field.isRequired = false
    } else {
      field.isRequired = field?.requiredForRoles?.includes(userRole)
      field.required = field?.requiredForRoles?.includes(userRole)
    }

    if (field.name === "followUpDateMHP") {
      field.required = false
    }
  })

  return {
    sectionFormFields: parseFormField(finalFormFields),
    validationSchema: createValidationSchema({ fields: finalFormFields }),
  }
}
