import { getSignedInUser } from "components/Auth/services/user.js"
import { getAvailableConsultTypes } from "services/general"

import { handleWaiveCheckbox } from "./handleWaiveCheckbox"
import { updateFollowUpDate } from "../MentalHealthForm/services/updateFollowUpDate"

import { GATSBY_PSYCHIATRIST_ROLE_ID } from "gatsby-env-variables"
import specialistReferral from "../utils/referral.json"

export const updateFormFields = ({
  formFields,
  assessmentOptions,
  consultDetails,
}) => {
  const { permission, userData } = getSignedInUser()

  formFields.forEach((field) => {
    if (field.name === "consultLoggedBy") {
      const { activeMedstaff } = getSignedInUser()
      let medstaffOptions = activeMedstaff?.map((medstaff) => {
        if (medstaff?.designation === "RN") {
          return `${medstaff?.firstName} ${medstaff?.lastName}, ${
            medstaff?.designation || medstaff?.license
          }`
        } else {
          return null
        }
      })
      field.options = medstaffOptions
    }

    if (field?.name === "waiveVitals") {
      field.onChange = handleWaiveCheckbox
      field.hideOptional = true
    }

    if (
      field?.name?.toLowerCase().includes("assessment") &&
      field?.consultType === "MGX1SOLCL"
    )
      field.options = assessmentOptions

    if (field?.name === "consultForm") {
      field.options = getAvailableConsultTypes({ userPermissions: permission })
    }

    if (field?.name === "doctorName") {
      const { medstaff } = getSignedInUser()
      let doctorOptions = []

      doctorOptions = medstaff
        ?.map((staff) => {
          if (staff.designation === "MD") {
            return `${staff?.firstName} ${staff?.lastName}, ${staff?.designation}`
          } else {
            return null
          }
        })
        .filter((staff) => !!staff)
      field.options = doctorOptions
    }

    if (field?.name === "doctorName") {
      const { medstaff } = getSignedInUser()
      let doctorOptions = []

      doctorOptions = medstaff
        ?.map((staff) => {
          if (staff.designation === "MD") {
            return `${staff?.firstName} ${staff?.lastName}, ${staff?.designation}`
          } else {
            return null
          }
        })
        .filter((staff) => !!staff)
      field.options = doctorOptions
    }

    if (field?.name === "specialistReferral") {
      if (userData?.role === GATSBY_PSYCHIATRIST_ROLE_ID) {
        field.options = specialistReferral["Psychiatrist"]
      } else {
        field.options = specialistReferral["Psychologist"]
      }
    }

    if (!!!consultDetails?.isDraft && consultDetails?.isDraft !== undefined) {
      field.isDisabled = true
      field.disabled = true
      field.isRequired = false
      // field.disabledOptions = field.options
    }

    if (field?.name === "riskAssessment") {
      field.onChange = updateFollowUpDate
    }
  })

  return formFields
}
