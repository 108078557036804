import React, { Fragment, useEffect, useState } from "react"

import DashboardLayout from "layout/DashboardLayout"
import PatientProfile from "../../Patients/utils/PatientProfile"
import OnsiteForm from "../OnsiteForm"
import MentalHealthForm from "../MentalHealthForm"
import Message from "../../Elements/Message"
import Loading from "elements/Loading"

import { getSignedInUser } from "../../Auth/services/user"
import { isBrowser } from "services/general"
import { getPatientConsultations } from "./services/getPatientConsultations"
import { getAllPatientDates } from "../../../components/Schedule/services/getAllDates.js"

import {
  GATSBY_MEDSTAFF_ROLE_ID,
  GATSBY_MHP_ROLE_ID,
  GATSBY_PSYCHIATRIST_ROLE_ID,
  GATSBY_DISABLE_ATTACHMENTS,
} from "gatsby-env-variables"

const ConsultationFormPage = ({ pageContext }) => {
  let [view, setView] = useState("Current Consultation")
  let [patient, setPatient] = useState({})
  let [consult, setConsult] = useState({})
  let [activeConsultations, setActiveConsultations] = useState([])
  const [pageLoading, setPageLoading] = useState(true)

  let { module, formFields } = pageContext
  const { userData } = getSignedInUser()

  let urlParameters
  let prevPage
  let consultId
  let patientId

  if (isBrowser()) {
    urlParameters = new URLSearchParams(window.location.search)
    prevPage = urlParameters?.get("prevPage")
    consultId = urlParameters?.get("consultId")
    patientId = urlParameters?.get("patientId")
  }

  const getActiveConsultations = async () => {
    setPageLoading(true)

    setActiveConsultations(await getPatientConsultations({ patientId }))
    await getConsultationFormDetails()
    setPageLoading(false)
  }

  useEffect(() => {
    getActiveConsultations()
    //eslint-disable-next-line
  }, [])

  const getConsultationFormDetails = async () => {
    let activeConsultation = JSON.parse(
      sessionStorage.getItem("activeConsultations")
    ).find((consultation) => consultation.id === consultId)

    let patientAllDates = await getAllPatientDates()

    let schedule = patientAllDates.find(
      (event) => event.id === activeConsultation?.googleCalendarEventId
    )

    if (schedule) activeConsultation = { ...activeConsultation, schedule }

    let consultPatient = JSON.parse(sessionStorage.getItem("patients")).find(
      (patient) => patient.id === patientId
    )
    //no issue, keep as is
    setPatient(consultPatient)

    setConsult(activeConsultation)
  }

  const getConsultationPage = ({ patient, consult }) => {
    switch (userData?.role) {
      case GATSBY_MEDSTAFF_ROLE_ID:
        return <OnsiteForm formFields={formFields} module={module} />

      case GATSBY_MHP_ROLE_ID:
      case GATSBY_PSYCHIATRIST_ROLE_ID:
        return (
          <MentalHealthForm
            formFields={formFields}
            module={module}
            patientDetails={patient}
            consultDetails={consult}
            activeConsultations={activeConsultations}
          />
        )

      default:
        return null
    }
  }

  return (
    <DashboardLayout
      seoTitle={module.title}
      title={
        view +
        " > " +
        (consult?.zendeskId !== null && consult?.zendeskId !== undefined
          ? consult?.zendeskId
          : "")
      }
      titleConfig={{
        styling: "formPageTitle",
        link: "/consultations",
      }}
      pageContext={pageContext}
      backPathUrl={prevPage}
      buttons={[
        {
          label: "Current Consultation",
          onClick: () => {
            setView("Current Consultation")
          },
          isDisabled: view === "Current Consultation",
        },
        {
          label: "Patient Profile",
          onClick: () => {
            setView("Patient Profile")
          },
          isDisabled: view === "Patient Profile",
        },
      ]}
    >
      {view === "Current Consultation" ? (
        <Fragment>
          <Message color="primary" align="left">
            This is{" "}
            <strong>
              {patient.firstName} {patient.lastName}'s
            </strong>{" "}
            current consultation. You may view their intake form before the
            consultation and accomplish the consultation notes during or after
            the consultation. Click the Patient Profile tab on the upper right
            corner of the screen to view previous consultation notes for this
            client.
          </Message>

          {pageLoading ? (
            <Loading />
          ) : (
            getConsultationPage({ patient, consult })
          )}
        </Fragment>
      ) : (
        <PatientProfile pageContext={pageContext} />
      )}
    </DashboardLayout>
  )
}

export default ConsultationFormPage
