import React, { useState, useEffect, useContext, Fragment } from "react"
import { navigate } from "gatsby"
import { Formik, Form } from "formik"
import classNames from "classnames"
import firebase from "firebase"

import Section from "elements/Section"
import ErrorMessage from "elements/ErrorMessage"
import Button from "elements/Button"
import SuccessAddModal from "../../Elements/SuccessAddModal.js"

import { updateInitialValues } from "./services/updateInitialValues.js"
import { generateFormField } from "elements/Form/services/form"
import { updateMHConsultation } from "./services/updateMHConsultation.js"
import { areAllFieldsOptional, isBrowser } from "services/general"
import { useCompleteMentalHealthFormFields } from "./hooks/useCompleteMentalHealthFormFields.js"
import { generateInitialValues } from "services/context"
import { AppContext } from "context/AppContext"
import { ConsultationContext } from "../ConsultationContext/ConsultationContext"

import { updateFormFields } from "../services/updateFormFields.js"
import { useAssessmentOptions } from "../hooks/useAssessmentOptions"
import { validateLeaveRecommendation } from "../services/validateLeaveRecommendation.js"

const CompleteMHForm = ({
  formFields,
  indexBuffer,
  view,
  module,
  consultDetails,
  values,
}) => {
  const [loading, setLoading] = useState(false)
  const [pending, setPending] = useState(false)
  const [patientDetails, setPatientDetails] = useState({})
  const { dispatch } = useContext(AppContext)
  const { consultationState, consultationDispatch } = useContext(
    ConsultationContext
  )
  let assessmentOptions = useAssessmentOptions()

  formFields = updateFormFields({
    formFields,
    assessmentOptions,
    consultDetails,
  })

  console.log(process.env.GATSBY_AIRTABLE_WEB_CONTENT)
  const {
    sectionFormFields,
    validationSchema,
  } = useCompleteMentalHealthFormFields({
    formFields: formFields,
    values,
  })

  let initialValues = {
    ...generateInitialValues({ fields: formFields }),
    ...consultDetails,
    ...patientDetails,
  }

  updateInitialValues(initialValues, formFields, consultDetails)

  const getPatientFromConsultation = async (userId) => {
    const patientInfo = await firebase
      .firestore()
      .collection("users")
      .doc(userId)
      .get()

    setPatientDetails(patientInfo.data() || {})
  }

  useEffect(() => {
    consultationDispatch({
      type: "REMOVE_DOCUMENTS",
    })

    if (consultDetails?.otherAttachments)
      consultationDispatch({
        type: "SAVE_DOCUMENTS",
        payload: consultDetails?.otherAttachments,
      })

    if (isBrowser()) getPatientFromConsultation(consultDetails?.userId)

    if (!!!consultDetails?.isDraft && consultDetails?.isDraft !== undefined) {
      setPending(!!!consultDetails?.isDraft)
    }

    //eslint-disable-next-line
  }, [])

  const handleDraftSubmit = async (values) => {
    let newValues = {}
    newValues = values
    newValues.patient = patientDetails
    newValues.consultStatus = view

    setLoading(true)

    await updateMHConsultation({
      values: newValues,
      clientConsultation: consultDetails,
      submitType: "saveAsDraft",
      formFields,
      consultationState, //contains the files uploaded under the otherAttachments fields
    })

    dispatch({
      type: "SHOW_MODAL",
      payload: {
        isCard: true,
        headerClass: `has-text-info has-background-info-light has-text-weight-bold is-size-5`,
        content: (
          <SuccessAddModal message={"Saved as Draft"} type={module.type} />
        ),
      },
    })

    setLoading(false)

    navigate("/consultations")
  }

  const handleSubmit = async (values) => {
    let newValues = {}
    newValues = values
    newValues.patient = patientDetails
    newValues.consultStatus = view

    setLoading(true)
    await updateMHConsultation({
      values: newValues,
      formFields,
      clientConsultation: consultDetails,
      submitType: "submit",
      errorCallback: () => {
        setLoading(false)
      },
      consultationState, //contains the files uploaded under the otherAttachments fields
    })

    dispatch({
      type: "SHOW_MODAL",
      payload: {
        isCard: true,
        headerClass: `has-text-info has-background-info-light has-text-weight-bold is-size-5`,
        content: (
          <SuccessAddModal
            message={`Consultation ${
              module.type === "add" ? "Logged" : "Edited"
            }`}
            type={module.type}
          />
        ),
      },
    })

    setLoading(false)

    navigate("/consultations")
  }

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize={true}
    >
      {({ values, setFieldValue, submitCount, errors }) => (
        <Form>
          {sectionFormFields
            .sort(
              (firstFormField, secondFormField) =>
                firstFormField.sectionId - secondFormField.sectionId
            )
            .map((section, index) => (
              <Fragment>
                <div className="is-flex is-flex-direction-row">
                  <div className="is-flex is-flex-direction-column is-flex-grow-1">
                    {
                      <Section
                        title={section?.section}
                        subtitle={section?.subtitle || ""}
                        id={section?.sectionId || ""}
                        addOns={
                          {
                            left: index + indexBuffer,
                            sectionIsOptional: areAllFieldsOptional(section)
                              ? "(optional)"
                              : "",
                          } || ""
                        }
                      >
                        {section?.fields.map((field, index) => {
                          if (
                            field.isFollowUpQuestion === null ||
                            !field.isFollowUpQuestion
                          ) {
                            return (
                              <Fragment>
                                {generateFormField({
                                  formFields: section?.fields,
                                  formField: field,
                                  values,
                                  setFieldValue,
                                  errors,
                                  context: {
                                    state: consultationState,
                                    dispatch: consultationDispatch,
                                  },
                                })}
                              </Fragment>
                            )
                          } else return null
                        })}
                      </Section>
                    }
                  </div>
                </div>
              </Fragment>
            ))}

          <ErrorMessage errors={errors} />
          {!pending && (
            <div className="isFullWidth is-flex is-justify-content-center columns is-vcentered">
              <Button
                type="button"
                color="transparent"
                disabled={loading}
                className={classNames(
                  "mt-2 mb-3 column pb-0 pt-0 is-offset-1 is-2 ml-0 mx-1",
                  { "is-loading": loading }
                )}
                onClick={() => handleDraftSubmit(values)}
              >
                Save as Draft
              </Button>
              <Button
                type="submit"
                color="primary"
                disabled={
                  loading || validateLeaveRecommendation(values, errors)
                }
                className={classNames(
                  "mt-2 mb-3 column pb-0 pt-0 is-offset-1 is-2 ml-0 mx-1 ",
                  { "is-loading": loading }
                )}
              >
                {module?.cta}
              </Button>
            </div>
          )}
        </Form>
      )}
    </Formik>
  )
}

export default CompleteMHForm
