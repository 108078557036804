import React, { useEffect, useState, useContext, Fragment } from "react"
import { navigate } from "gatsby"
import classNames from "classnames"
import firebase from "firebase"

import Button from "elements/Button"
import SuccessAddModal from "../../Elements/SuccessAddModal.js"

import { updateMHConsultation } from "./services/updateMHConsultation.js"
import { isBrowser } from "services/general"
import { AppContext } from "context/AppContext"
import { ConsultationContext } from "../ConsultationContext/ConsultationContext.js"

const NoShowMHForm = ({ values, view, module, consultDetails }) => {
  const [loading, setLoading] = useState(false)
  const [pending, setPending] = useState(false)
  const [patientDetails, setPatientDetails] = useState({})
  const { dispatch } = useContext(AppContext)
  const { consultationState, consultationDispatch } = useContext(
    ConsultationContext
  )

  const getPatientFromConsultation = async (userId) => {
    const patientInfo = await firebase
      .firestore()
      .collection("users")
      .doc(userId)
      .get()

    setPatientDetails(patientInfo.data() || {})
  }

  useEffect(() => {
    if (isBrowser()) getPatientFromConsultation(consultDetails?.userId)

    if (!!!consultDetails?.isDraft && consultDetails?.isDraft !== undefined) {
      setPending(!!!consultDetails?.isDraft)
    }
    //eslint-disable-next-line
  }, [])

  const handleSubmit = async (values, submitType) => {
    let newValues = {
      ...values,
      patient: patientDetails,
      consultStatus: view,
      consultForm: "Mental Health",
    }

    setLoading(true)

    await updateMHConsultation({
      values: newValues,
      clientConsultation: consultDetails,
      submitType: submitType,
      consultationDispatch,
      consultationState,
    })

    switch (submitType) {
      case "saveAsDraft":
        dispatch({
          type: "SHOW_MODAL",
          payload: {
            isCard: true,
            headerClass: `has-text-info has-background-info-light has-text-weight-bold is-size-5`,
            content: (
              <SuccessAddModal message={"Saved as Draft"} type={module.type} />
            ),
          },
        })

        break
      case "submit":
      default:
        dispatch({
          type: "SHOW_MODAL",
          payload: {
            isCard: true,
            headerClass: `has-text-info has-background-info-light has-text-weight-bold is-size-5`,
            content: (
              <SuccessAddModal
                message={`Consultation ${
                  module.type === "add" ? "Logged" : "Edited"
                }`}
                type={module.type}
              />
            ),
          },
        })
    }

    setLoading(false)

    switch (submitType) {
      case "saveAsDraft":
        navigate("/consultations")
        break
      case "submit":
      default:
        navigate("/consultations")
    }
  }

  return (
    <Fragment>
      {!pending && (
        <div className="isFullWidth is-flex is-justify-content-center columns">
          <Button
            type="button"
            color="transparent"
            disabled={loading}
            className={classNames(
              "mt-2 mb-3 column pb-0 pt-0 is-offset-1 is-2 ml-0 mx-1",
              {
                "is-loading": loading,
              }
            )}
            onClick={() => handleSubmit(values, "saveAsDraft")}
          >
            Save as Draft
          </Button>
          <Button
            type="submit"
            color="primary"
            disabled={loading}
            className={classNames(
              "mt-2 mb-3 column pb-0 pt-0 is-offset-1 is-2 ml-0 mx-1",
              {
                "is-loading": loading,
              }
            )}
            onClick={() => handleSubmit(values, "submit")}
          >
            {module?.cta}
          </Button>
        </div>
      )}
    </Fragment>
  )
}

export default NoShowMHForm
