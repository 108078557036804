import React, { useState, useEffect, useContext, Fragment } from "react"
import { navigate } from "gatsby"
import { Formik, Form } from "formik"
import classNames from "classnames"

import Section from "elements/Section"
import VitalsSection from "./VitalsSection"
import ErrorMessage from "elements/ErrorMessage"
import Button from "elements/Button"
import SuccessAddModal from "../../Elements/SuccessAddModal.js"

import { generateFormField } from "elements/Form/services/form"
import { addOnsiteConsultation } from "./services/addOnsiteConsultation.js"
import { editOnsiteConsultation } from "./services/editOnsiteConsultation.js"
import { ConsultationContext } from "../ConsultationContext/ConsultationContext.js"
import { areAllFieldsOptional, isBrowser } from "services/general"
import { useOnsiteFormFields } from "./hooks/useOnsiteFormFields.js"

import { updateFormFields } from "../services/updateFormFields.js"
import { useAssessmentOptions } from "../hooks/useAssessmentOptions"
import { generateInitialValues } from "services/context"

import { AppContext } from "context/AppContext"

const OnsiteForm = ({ formFields, module }) => {
  const [loading, setLoading] = useState(false)
  const { consultationState, consultationDispatch } = useContext(
    ConsultationContext
  )

  let selectedConsultation = isBrowser()
    ? JSON.parse(sessionStorage.getItem("selectedConsultation"))
    : {}

  const { dispatch } = useContext(AppContext)

  const { sectionFormFields, validationSchema } = useOnsiteFormFields({
    formFields: formFields,
  })

  let initialValues

  switch (module.type) {
    case "add":
      initialValues = {
        ...generateInitialValues({ fields: formFields }),
        ...consultationState,
      }
      break

    case "edit":
      initialValues = {
        ...generateInitialValues({ fields: formFields }),
        ...selectedConsultation,
        consultForm:
          selectedConsultation.consultType || selectedConsultation.consultForm,
      }
      break

    default:
  }

  let assessmentOptions = useAssessmentOptions()

  updateFormFields({
    formFields,
    assessmentOptions,
  })

  useEffect(() => {
    if (isBrowser()) {
      consultationDispatch({ type: "GET_CONTEXT_FROM_SESSION" })
    }
  }, [consultationDispatch])

  const handleSubmit = async (values) => {
    setLoading(true)

    switch (module.type) {
      case "add":
        await addOnsiteConsultation({ values, consultationDispatch })
        break

      case "edit":
        await editOnsiteConsultation({ values, consultationDispatch })
        break

      default:
        break
    }

    dispatch({
      type: "SHOW_MODAL",
      payload: {
        isCard: true,
        headerClass: `has-text-info has-background-info-light has-text-weight-bold is-size-5`,
        content: (
          <SuccessAddModal
            message={`Consultation ${
              module.type === "add" ? "Logged" : "Edited"
            }`}
            type={module.type}
          />
        ),
      },
    })

    setLoading(false)

    navigate("/consultations")
  }

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize={true}
    >
      {({ values, setFieldValue, submitCount, errors }) => (
        <Form>
          {sectionFormFields
            .sort(
              (firstFormField, secondFormField) =>
                firstFormField.order - secondFormField.order
            )
            .map((section, index) => (
              <Fragment>
                <div className="is-flex is-flex-direction-row">
                  <div className="is-flex is-flex-direction-column is-flex-grow-1">
                    {
                      <Section
                        title={section?.section}
                        subtitle={section?.subtitle || ""}
                        id={section?.sectionId || ""}
                        addOns={
                          {
                            left: index + 1,
                            sectionIsOptional: areAllFieldsOptional(section)
                              ? "(optional)"
                              : "",
                          } || ""
                        }
                      >
                        {section?.fields.map((field) => {
                          if (
                            field.name === "chiefComplaint" ||
                            field.name === "historyOfPresentIllness"
                          ) {
                            return (
                              <Fragment>
                                {generateFormField({
                                  formFields: section?.fields,
                                  formField: field,
                                  values,
                                  setFieldValue,
                                  submitCount,
                                  errors,
                                })}
                              </Fragment>
                            )
                          } else return null
                        })}
                        <VitalsSection
                          section={section}
                          values={values}
                          setFieldValue={setFieldValue}
                          submitCount={submitCount}
                          errors={errors}
                          consultType={"Teleconsult"}
                        />

                        {section?.fields.map((field) => {
                          if (
                            field.sectionSubtitle !== "Vital Signs" &&
                            field.name !== "chiefComplaint" &&
                            field.name !== "historyOfPresentIllness"
                          ) {
                            return (
                              <Fragment>
                                {generateFormField({
                                  formFields: section?.fields,
                                  formField: field,
                                  values,
                                  setFieldValue,
                                  submitCount,
                                  errors,
                                  dispatch,
                                })}
                              </Fragment>
                            )
                          } else {
                            return null
                          }
                        })}
                      </Section>
                    }
                  </div>
                </div>
              </Fragment>
            ))}

          <ErrorMessage errors={errors} />

          <div className="isFullWidth is-flex is-justify-content-center columns">
            <Button
              type="button"
              color="transparent"
              disabled={loading}
              className={classNames(
                "mt-2 mb-3 column pb-0 pt-0 is-offset-1 is-2",
                { "is-loading": loading }
              )}
              onClick={() => navigate("/consultations")}
            >
              Back
            </Button>
            <Button
              type="submit"
              color="primary"
              disabled={loading}
              className={classNames(
                "mt-2 mb-3 column pb-0 pt-0 is-offset-1 is-2",
                { "is-loading": loading }
              )}
            >
              {module?.cta}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  )
}
export default OnsiteForm
